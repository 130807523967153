<template>
  <div class="floating_widget" :class="opened ? 'opened' : ''">
    <div class="floating_widget_button" @click="toggle()">
      <div class="icons">
        <i class="telegram_icon" />
      </div>
    </div>
    <div class="floating_widget_items">
      <a href="viber://chat?number=+380668010075" class="viber" aria-label="Viber">
        <i class="viber_icon" />
      </a>
      <a href="https://t.me/+380668010075" target="_blank" class="telegram" aria-label="Telegram">
        <i class="telegram_icon" />
      </a>
      <a href="tel:+380668010075" class="phone" aria-label="Phone">
        <i class="phone_icon" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      opened: false
    }
  },
  methods: {
    toggle () {
      this.opened = !this.opened
    }
  }
}
</script>
