<template>
  <div
    v-if="callback_opened"
  >
    <div
      id="modalBg"
      class="modal_bg opened"
      @click="hide()"
    />
    <div class="push_up call_back">
      <div class="newsletter">
        <div class="newsletter_inner">
          <h2 class="newsletter_title" v-text="$t('callback.title')" />
          <template v-if="!accepted">
            <p class="newsletter_desct" v-text="$t('callback.subtitle')" />
            <div class="newsletter_form">
              <div class="input_group" :class="errors && errors.phone ? 'error' : ''">
                <masked-input
                  v-model="phone"
                  type="tel"
                  class="custom_input"
                  mask="\+38(111)111-11-11"
                  placeholder=" "
                />
                <span class="floating_label" v-text="$t('forms.phone')" />
                <i class="phone_icon" />
              </div>
              <button type="button" class="submit_btn" @click="sendMessage">
                <i class="letter_icon" />
              </button>
            </div>
          </template>
          <div v-else class="newsletter_desct" v-text="$t('forms.cs_succes_title')" />
        </div>
        <div class="bg_image" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      type: 'callback',
      phone: null,
      callback_opened: false,
      accepted: false
    }
  },
  computed: {
    ...mapGetters('validation', ['errors']),
    managerOnline () {
      const today = new Date()
      return (today.getHours() > 8 && today.getHours() < 21)
    }
  },
  mounted () {
    this.$nuxt.$on('callback_open', this.show)
    this.$nuxt.$on('callback_hide', this.hide)
  },
  beforeDestroy () {
    this.$nuxt.$off('callback_open')
    this.$nuxt.$off('callback_hide')
  },
  destroyed () {
    this.clearErrors()
  },
  methods: {
    ...mapActions('validation', ['setErrors', 'clearErrors']),
    show () {
      this.callback_opened = true
    },
    hide () {
      const self = this
      setTimeout(function () {
        self.callback_opened = false
        self.accepted = false
      }, 500)
    },
    sendMessage () {
      if (this.phone && this.phone.replace(/[^0-9 ]/g, '').length === 12) {
        this.$axios.post('/api/promo/messages/', {
          phone: this.phone.replace(/[^0-9 ]/g, ''),
          type: this.type
        })
          .then(() => {
            this.accepted = true
            this.$fb.track('Lead')
            const self = this
            setTimeout(function () {
              self.callback_opened = false
            }, 2000)
          })
          .catch((error) => {
            this.accepted = false
            this.setErrors(error.response.data.errors)
          })
      } else {
        this.setErrors([{ phone: '12' }])
      }
    }
  }
}
</script>
