<template>
  <a :href="getUrl" class="cart_item">
    <div class="product_info">
      <div class="product_img_wrap">
        <div class="product_img">
          <img :src="$options.filters.mediaUrl(product, 'avatar', 'url')" loading="lazy">
        </div>
      </div>
      <div class="product_descr">
        <div class="product_descr_wrap">
          <div class="is_available_wrap">
            <div class="product_code" v-text="product.sku" />
            <div v-if="inStock" class="is_available">
              <i class="icon" />
              {{ $t('catalog.is_available') }}
            </div>
            <div v-else class="is_available is_no_available">
              {{ $t('catalog.is_no_available') }}
            </div>
          </div>
          <p class="product_link" v-text="product.title" />
          <div class="price_wrap">
            <div class="price_current">
              <p v-if="specialPrice">
                <small><del v-text="money(normalPrice)" /></small>
                {{ money(specialPrice) }}
              </p>
              <p v-else>
                {{ money(normalPrice) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="add_to_favorite" @click.prevent="syncWishlist">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_105_16534)">
          <path d="M11.2738 1.9982C10.6035 2.00863 9.94788 2.19573 9.37306 2.54062C8.79824 2.88551 8.32461 3.37598 7.99999 3.96249C7.67537 3.37598 7.20173 2.88551 6.62691 2.54062C6.05209 2.19573 5.39645 2.00863 4.72618 1.9982C3.65769 2.04462 2.651 2.51204 1.92605 3.29834C1.20109 4.08463 0.816802 5.12589 0.85713 6.19463C0.85713 8.90118 3.70594 11.8571 6.09522 13.8613C6.62869 14.3096 7.30317 14.5554 7.99999 14.5554C8.6968 14.5554 9.37129 14.3096 9.90475 13.8613C12.294 11.8571 15.1428 8.90118 15.1428 6.19463C15.1832 5.12589 14.7989 4.08463 14.0739 3.29834C13.349 2.51204 12.3423 2.04462 11.2738 1.9982ZM9.13987 12.9506C8.82081 13.2193 8.4171 13.3666 7.99999 13.3666C7.58287 13.3666 7.17916 13.2193 6.86011 12.9506C3.80177 10.3845 2.04761 7.9226 2.04761 6.19463C2.00692 5.44148 2.26568 4.7028 2.76746 4.13969C3.26925 3.57657 3.97334 3.23472 4.72618 3.18868C5.47901 3.23472 6.18311 3.57657 6.68489 4.13969C7.18667 4.7028 7.44544 5.44148 7.40475 6.19463C7.40475 6.3525 7.46746 6.5039 7.57909 6.61552C7.69072 6.72715 7.84212 6.78987 7.99999 6.78987C8.15785 6.78987 8.30925 6.72715 8.42088 6.61552C8.53251 6.5039 8.59522 6.3525 8.59522 6.19463C8.55453 5.44148 8.8133 4.7028 9.31508 4.13969C9.81687 3.57657 10.521 3.23472 11.2738 3.18868C12.0266 3.23472 12.7307 3.57657 13.2325 4.13969C13.7343 4.7028 13.9931 5.44148 13.9524 6.19463C13.9524 7.9226 12.1982 10.3845 9.13987 12.9482V12.9506Z" fill="#36287C" />
        </g>
        <defs>
          <clipPath id="clip0_105_16534">
            <rect width="14.2857" height="14.2857" fill="white" transform="translate(0.857178 0.857147)" />
          </clipPath>
        </defs>
      </svg>
    </span>
  </a>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('cart', ['sessionId']),
    getUrl () {
      return this.localePath({ name: 'product-slug', params: { slug: this.product.slug } }) + '/'
    },
    normalPrice () {
      return this.product.prices_sum_price
    },
    sortedSales () {
      const productSales = this.product.salesFront || []
      const categorySales = this.product.categories?.[0]?.salesFront || []
      return [...productSales, ...categorySales].sort((a, b) => a.position - b.position)
    },
    specialPrice () {
      if (this.sortedSales && this.sortedSales.length) {
        if (this.sortedSales[0].type === 'percent') {
          return parseInt(this.normalPrice - (this.normalPrice * this.sortedSales[0].value) / 100)
        }
        if (this.sortedSales[0].type === 'fixed') {
          return parseInt(this.normalPrice - this.sortedSales[0].value)
        }
      }
      return this.product.discounts_sum_price
    },
    inWishlist () {
      return this.$store.state.cart.favorites &&
          Object.values(this.$store.state.cart.favorites).filter((item) => { return item.id === this.product.id }).length
    },
    inWishlistId () {
      return this.inWishlist ? Object.values(this.$store.state.cart.favorites).filter((item) => { return item.id === this.product.id })[0].rowId : false
    },
    inStock () {
      return this.product.stock_status === 'available' && this.product.stocks_many_sum_quantity > 0
    }
  },
  methods: {
    ...mapActions('cart', ['setCart', 'setFavorites']),
    money (price) {
      return this.$moneyFormat(price)
    },
    syncWishlist () {
      if (!this.inWishlist) {
        this.$axios.post('/api/cart/', {
          product_id: this.product.id,
          quantity: 1,
          price: this.normalPrice,
          sale_price: 0
        }, {
          headers: {
            'Cart-Type': 'favorites',
            'Old-Guest-Token': `${this.sessionId}`
          }
        })
          .then((res) => {
            this.setFavorites(res.data)
          })
      } else {
        this.$axios.delete(`/api/cart/${this.inWishlistId}/`, {
          headers: {
            'Cart-Type': 'favorites',
            'Old-Guest-Token': `${this.sessionId}`
          }
        })
          .then((res) => {
            this.setFavorites(res.data)
          })
      }
    }
  }
}
</script>
