<template>
  <div v-if="!emptyUpsales" class="cart_with">
    <h3 v-text="$t('cart.upsale_title')" />
    <div class="cart_with_list cart_swiper swiper">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in upsells" :key="`${index}_upsell_item`" class="swiper-slide">
          <ProductItem :product="item" />
        </div>
      </div>
      <div class="small_prev">
        <img src="/icons/angle-swiper.svg" alt="prev-angle-swiper">
      </div>
      <div class="small_next">
        <img src="/icons/angle-swiper.svg" alt="next-angle-swiper">
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Swiper, Navigation, Autoplay } from 'swiper'
import ProductItem from '@/components/product/ProductItem'
Swiper.use([Navigation, Autoplay])

export default {
  components: {
    ProductItem
  },
  computed: {
    ...mapGetters('cart', ['upsells']),
    emptyUpsales () {
      return !this.upsells.length
    }
  },
  mounted () {
    return new Swiper('.cart_swiper', {
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      spaceBetween: 10,
      autoplay: {
        delay: 3000
      },
      navigation: {
        nextEl: '.cart_with_list .small_next',
        prevEl: '.cart_with_list .small_prev'
      },
      breakpoints: {
        300: {
          slidesPerView: 'auto',
          spaceBetween: 10
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20
        }
      }
    })
  }
}
</script>

<style>
.cart_with_list.swiper .product_item_wrap {
  min-height: auto;
}
</style>
