<template>
  <div
    v-if="cart_opened"
  >
    <div
      id="modalBg"
      class="modal_bg opened"
      @click="hide()"
    />

    <div
      class="modal custom_scrollbar cart_modal"
      :class="cart_class"
    >
      <button
        class="modal_close"
        @click="hide()"
      />
      <div v-if="!emptyCartItems" class="cart_wrap">
        <div class="cart_top">
          <p class="title" v-text="$t('cart.title')" />
          <div class="total_sm_wrap">
            <span class="count" v-text="total" />
            {{ $t('cart.items1') }}
            <span class="sum" v-text="money(totals.sub_total)" />
          </div>
          <div class="cart_list custom_scrollbar">
            <CartItem v-for="(row, index) in getCartItems" :key="`${index}_cart_row`" :item="row" />
          </div>
        </div>
        <CartUpsale />
        <div class="cart_total">
          <div class="total_wrap">
            <div class="total_price_wrap">
              <p class="title" v-text="$t('product.total_price')" />
              <p class="total_price" v-text="money(totals.sub_total)" />
            </div>
            <div class="total_nav">
              <nuxt-link :to="localePath('checkout') + '/'" class="orange_btn add_to_cart">
                <i class="icon" />
                <span v-text="$t('cart.checkout')" />
              </nuxt-link>
              <!--              <nuxt-link :to="localePath('checkout') + '/'" class="btn_white">-->
              <!--                {{ $t('product.buy_one_click') }}-->
              <!--              </nuxt-link>-->
            </div>
          </div>
        </div>
      </div>
      <div v-else class="cart_wrap">
        <div class="cart_top">
          <p class="title" v-text="$t('cart.title_empty')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CartItem from '../cart/CartItem'
import CartUpsale from '../cart/CartUpsale'

export default {
  components: {
    CartItem,
    CartUpsale
  },
  data () {
    return {
      cart_class: 'closed',
      cart_opened: false,
      promo_opened: false,
      coupon_code: null,
      one_click_phone: null
    }
  },
  computed: {
    ...mapGetters('cart', [
      'sessionId',
      'cart',
      'totals',
      'total'
    ]),
    ...mapGetters('validation', ['errors']),
    emptyCartItems () {
      return !Object.values(this.cart).length
    },
    getCartItems () {
      return Object.values(this.cart)
    },
    promoClass () {
      return this.coupon ? 'adding success' : this.promo_opened ? 'adding' : ''
    }
  },
  mounted () {
    this.$nuxt.$on('cart_open', this.show)
    this.$nuxt.$on('cart_hide', this.hide)
  },
  beforeDestroy () {
    this.$nuxt.$off('cart_open')
    this.$nuxt.$off('cart_hide')
  },
  destroyed () {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    ...mapActions('cart', ['setCart', 'synchFavorite', 'setOrder']),
    ...mapActions('validation', ['setErrors', 'setSuccess', 'clearErrors']),
    money (price) {
      return this.$moneyFormat(price)
    },
    show () {
      this.cart_opened = true
      const self = this
      setTimeout(function () {
        self.cart_class = 'opened'
      }, 300)
    },
    hide () {
      this.cart_class = 'closed'
      const self = this
      setTimeout(function () {
        self.cart_opened = false
      }, 500)
    },
    async addQuickOrder () {
      try {
        if (this.one_click_phone) {
          await this.$axios.post('/api/promo/orders/quick/', {
            phone: this.one_click_phone.replace(/[^0-9 ]/g, '')
          }, {
            headers: {
              'Old-Guest-Token': `${this.sessionId}`
            }
          })
            .then((res) => {
              this.setOrder(res.data)
              this.hide()
              this.$router.push(this.localePath({ name: 'checkout-success' }))
            })
            .catch((error) => {
              if (error.response) {
                this.$toast.error(error.response.data.message)
              } else {
                this.$toast.error(error.message)
              }
            })
        }
      } catch (e) {
        // console.log(e)
      }
      await this.$axios.get('/api/cart/', {
        headers: {
          'Old-Guest-Token': `${this.sessionId}`
        }
      })
        .then((res) => {
          this.setCart(res.data)
        })
    }
  }
}
</script>
