<template>
  <div class="product_item_wrap" :class="specialPrice ? 'has-sale' : ''">
    <div class="product_item">
      <div class="product_image_wrap">
        <div v-if="mergedStickers" class="stickers">
          <template v-for="(sticker, key) in mergedStickers">
            <div
              v-if="sticker"
              :key="`${key}_pr_in_list`"
              class="sticker"
              :style="`background:${sticker.background}; color:${sticker.color};`"
              v-text="sticker.title"
            />
          </template>
        </div>
        <div class="actions">
          <button class="add_to_favorite" :class="inWishlistId ? 'added' : ''" aria-label="Add to favorite" @click="syncWishlist" />
        </div>
        <nuxt-link :to="getUrl" class="product_image">
          <nuxt-img
            :src="$options.filters.mediaUrl(product, 'avatar', 'path')"
            :alt="$imgMeta('alt', product.title)"
            :title="$imgMeta('title', product.title)"
            width="200"
            height="200"
            format="webp"
            background="transparent"
            layout="responsive"
            fit="contain"
            :loading="loading"
          />
        </nuxt-link>
      </div>
      <div class="pi_info">
        <nuxt-link v-if="product.reviews_count" :to="`${getUrl}#reviews`" class="product_rating_wrap">
          <Rating :rating="product.rating" />
          <div class="reviews_count">
            <span>{{ product.reviews_count }}</span> {{ $t('catalog.reviews') }}
          </div>
        </nuxt-link>
        <div class="is_available_wrap">
          <div v-if="product.sku" class="product_code">
            {{ $t('product.sku') }}{{ product.sku }}
          </div>
          <div v-if="inStock" class="is_available">
            <i class="icon" />
            {{ $t('catalog.is_available') }}
          </div>
          <div v-else class="is_available is_no_available">
            {{ $t('catalog.is_no_available') }}
          </div>
          <div v-if="isDrop" class="is_drop" v-text="`DR`" />
        </div>
        <nuxt-link
          :to="getUrl"
          class="product_link"
        >
          {{ product.title }}
        </nuxt-link>
      </div>
      <div class="pi_bottom">
        <div v-if="specialPrice" class="price">
          <i v-text="$i18n.t('catalog.filter_price')" />
          <del>{{ money(normalPrice) }}</del>
          <span>{{ money(specialPrice) }}</span>
        </div>
        <div v-else class="price">
          <i v-text="$i18n.t('catalog.filter_price')" />
          <span>{{ money(normalPrice) }}</span>
        </div>
        <div class="add_to_cart_wrap">
          <button v-if="inStock" :aria-label="$t('catalog.btn_buy')" class="orange_btn add_to_cart" @click="addToCart">
            <i class="icon" />
            <span v-text="inCart ? $t('catalog.btn_buy_active') : $t('catalog.btn_buy')" />
          </button>
        </div>
      </div>
      <div v-if="attributesList && !inCarousel" class="hidden_info">
        <template v-for="(attr, index) in attributesList">
          <div v-if="index < 5" :key="`pa_${index}`" class="hi_item">
            <span v-text="attr.attribute.title" />
            <span v-text="attr.value.title" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Rating from '@/components/product/Rating.vue'

export default {
  components: {
    Rating
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    inCarousel: {
      type: Boolean,
      default: false
    },
    loading: {
      type: String,
      default: 'lazy'
    }
  },
  computed: {
    ...mapGetters('cart', ['sessionId']),
    getUrl () {
      return this.localePath({ name: 'product-slug', params: { slug: this.product.slug } }) + '/'
    },
    normalPrice () {
      return this.product.prices_sum_price
    },
    sortedSales () {
      const productSales = this.product.salesFront || []
      const categorySales = this.product.categories?.[0]?.salesFront || []
      return [...productSales, ...categorySales].sort((a, b) => a.position - b.position)
    },
    mergedStickers () {
      const result = this.product.stickers.slice()
      this.sortedSales.forEach((sale) => {
        if (
          sale.stickers.length &&
          sale.stickers[0] &&
          (sale.type !== 'delivery' || (sale.type === 'delivery' && sale.value < this.normalPrice))
        ) {
          result.push(sale.stickers[0])
        }
      })
      return result
    },
    specialPrice () {
      if (this.sortedSales && this.sortedSales.length) {
        if (this.sortedSales[0].type === 'percent') {
          return parseInt(this.normalPrice - (this.normalPrice * this.sortedSales[0].value) / 100)
        }
        if (this.sortedSales[0].type === 'fixed') {
          return parseInt(this.normalPrice - this.sortedSales[0].value)
        }
      }
      return this.product.discounts_sum_price
    },
    inStock () {
      return this.product.stock_status === 'available' && this.product.stocks_many_sum_quantity > 0
    },
    isDrop () {
      return this.user && this.user.role.mode === 'admin' && this.product.stocks &&
        this.product.stocks.some(stock => stock.address === 'drop') &&
        (!this.product.stocks.some(stock => stock.address === 'one_ec') || this.product.stocks.some(stock => stock.address === 'one_ec' && stock.quantity === 0))
    },
    inWishlist () {
      return this.$store.state.cart.favorites &&
        Object.values(this.$store.state.cart.favorites).filter((item) => { return item.id === this.product.id }).length
    },
    inWishlistId () {
      return this.inWishlist ? Object.values(this.$store.state.cart.favorites).filter((item) => { return item.id === this.product.id })[0].rowId : false
    },
    inCart () {
      return this.$store.state.cart.cart &&
        Object.values(this.$store.state.cart.cart).filter((item) => { return item.id === this.product.id }).length
    },
    attributesList () {
      return (!this.product.attributesHover || !this.product.attributesHover.length)
        ? null
        : [...this.product.attributesHover].sort((a, b) => a.position - b.position)
    }
  },
  methods: {
    ...mapActions('cart', ['setCart', 'setFavorites', 'setUpsells']),
    money (price) {
      return this.$moneyFormat(price)
    },
    getCategoryUrl (slug) {
      const path = '/category/' + slug
      return this.localePath({ path })
    },
    syncWishlist () {
      if (!this.inWishlist) {
        this.$axios.post('/api/cart/', {
          product_id: this.product.id,
          quantity: 1,
          price: this.normalPrice,
          sale_price: 0
        }, {
          headers: {
            'Cart-Type': 'favorites',
            'Old-Guest-Token': `${this.sessionId}`
          }
        })
          .then((res) => {
            this.setFavorites(res.data)
          })
      } else {
        this.$axios.delete(`/api/cart/${this.inWishlistId}/`, {
          headers: {
            'Cart-Type': 'favorites',
            'Old-Guest-Token': `${this.sessionId}`
          }
        })
          .then((res) => {
            this.setFavorites(res.data)
          })
      }
    },
    addToCart () {
      if (!this.inCart) {
        this.$axios.post('/api/cart/', {
          product_id: this.product.id,
          quantity: 1,
          price: this.normalPrice,
          sale_price: this.specialPrice,
          image: this.$options.filters.mediaUrl(this.product, 'avatar', 'url')
        }, {
          headers: {
            'Old-Guest-Token': `${this.sessionId}`
          }
        })
          .then((res) => {
            this.setCart(res.data)
            this.$nuxt.$emit('cart_open')
            this.updateUpsales()
            this.$fb.track('AddToCart',
              {
                content_name: this.product.title,
                content_category: this.product.categories ? this.product.categories[0].title : '',
                content_ids: [this.product.id],
                content_type: 'product',
                value: this.specialPrice > 0 ? this.specialPrice : this.normalPrice,
                currency: 'UAH'
              }
            )
            this.$gtm.push(
              {
                event: 'add_to_cart',
                event_category: 'tocart',
                ecommerce: {
                  items: [{
                    item_name: this.product.title,
                    item_id: this.product.id,
                    sku: this.product.sku,
                    price: this.specialPrice > 0 ? this.specialPrice : this.normalPrice,
                    currency: 'UAH',
                    quantity: 1,
                    item_category: this.product.categories ? this.product.categories[0].title : ''
                  }]
                }
              }
            )
          })
      }
    },
    updateUpsales () {
      this.$axios.get('/api/promo/products/', { params: { category: this.product.categories ? this.product.categories[0].slug : null, upsells: 1, per_page: 20 } }).then((res) => {
        if (res.status === 200 && res.data.data.length) {
          this.setUpsells(res.data)
        }
      })
    }
  }
}
</script>
