<template>
  <div id="global_wrap">
    <Header />
    <div>
      <nuxt />
    </div>
    <Footer />
    <client-only>
      <Cart />
      <Callback />
    </client-only>
  </div>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Cart from '../components/modals/Cart'
import Callback from '../components/modals/Callback'

export default {
  components: {
    Header,
    Footer,
    Cart,
    Callback
  },
  mounted () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
}
</script>
