<template>
  <div class="cart_item">
    <div class="product_img">
      <img :src="item.options.image ? item.options.image : ''" loading="lazy">
    </div>
    <div class="product_descr_wrap">
      <div class="product_descr">
        <span class="title" v-text="item.name" />
        <div class="counter_wrap">
          <div class="counter">
            <button
              class="counter_btn subtract c_item"
              :class="item.qty === 1 ? 'disabled' : ''"
              aria-label="update cart item"
              @click="updateCartItem(item.rowId, item.qty - 1)"
              v-text="`-`"
            />
            <input type="text" :value="item.qty" class="counter_number c_item" @change="updateCartItem(item.rowId, $event.target.value)">
            <button
              class="counter_btn add c_item"
              aria-label="update cart item"
              @click="updateCartItem(item.rowId, item.qty + 1)"
              v-text="`+`"
            />
          </div>
          <div class="price_wrap">
            <div v-if="item.options.sale_price" class="price_old">
              <del v-text="money(item.price * item.qty)" />
            </div>
            <div class="price_current">
              <p v-if="item.options.sale_price" v-text="money(item.options.sale_price * item.qty)" />
              <p v-else v-text="money(item.price * item.qty)" />
            </div>
          </div>
        </div>
      </div>
      <button class="delete_from_chart" @click="deleteCartItem(item.rowId)">
        <i class="icon" />
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('cart', ['sessionId', 'cart', 'coupon'])
  },
  methods: {
    ...mapActions('cart', ['setCart']),
    money (price) {
      return this.$moneyFormat(price)
    },
    updateCartItem (id, quantity) {
      this.$axios.put(`/api/cart/${id}/`, {
        quantity: parseInt(quantity)
      }, {
        headers: {
          'Old-Guest-Token': `${this.sessionId}`
        }
      })
        .then((res) => {
          this.setCart(res.data)
        })
    },
    deleteCartItem (id) {
      this.$axios.delete(`/api/cart/${id}/`, {
        headers: {
          'Old-Guest-Token': `${this.sessionId}`
        }
      })
        .then((res) => {
          this.setCart(res.data)
        })
    }
  }
}
</script>
