<template>
  <div class="header_socials">
    <template v-for="(item, index) in sortedItems">
      <a
        :key="`${index}_header_link`"
        :href="item.href"
        class="social_link"
        rel="nofollow"
        target="_blank"
      >
        <img
          :src="$options.filters.mediaUrl(item, 'avatar', 'url')"
          :alt="$imgMeta('alt', item.title)"
          :title="$imgMeta('title', item.title)"
          width="23"
          height="23"
        >
      </a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      default: null
    }
  },
  computed: {
    sortedItems () {
      return [...this.menu].sort((a, b) => a.position - b.position)
    }
  }
}
</script>
