<template>
  <div class="header_nav">
    <div
      v-for="(item, index) in sortedItems"
      :key="`${index}_header_link`"
      class="nav_item"
    >
      <template v-if="!item.blank">
        <nuxt-link
          :to="$formatUrl($options.filters.transl(item, 'href', $i18n.locale))"
          class="nav_link"
          :class="item.href.includes('promotions') ? 'sales_link' : ''"
          v-html="$options.filters.transl(item, 'title', $i18n.locale)"
        />
      </template>
      <template v-else>
        <a
          :href="$options.filters.transl(item, 'href', $i18n.locale)"
          class="nav_link"
          target="_blank"
          v-html="$options.filters.transl(item, 'title', $i18n.locale)"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    menu: {
      type: Array,
      default: null
    }
  },
  computed: {
    sortedItems () {
      return [...this.menu].sort((a, b) => a.position - b.position)
    }
  }
}
</script>
