<template>
  <form
    v-click-outside="closeResults"
    :action="$formatUrl(localePath('search'))"
    class="header_search_row"
    :class="{ 'active': active }"
    @click="active = true"
  >
    <button type="submit" class="header_search_btn" aria-label="Search" />
    <input
      id="searchTop"
      v-model="query"
      name="query"
      class="header_search_input"
      type="text"
      :placeholder="$t('header.search_placeholder')"
      @input="filterResults"
    >
    <div class="search_autocomplete_wrap">
      <div v-if="products" class="search_autocomplete results">
        <div class="autocomplete">
          <div class="autocomplete_top">
            <div class="autocomplete_title">
              <p>{{ $t('header.search_results') }}</p>
            </div>
            <a v-if="total > 0" :href="fullLink" class="orange_link">
              <span>{{ $t('header.search_show_all') }} ({{ total }})</span>
              <i class="arrow_right" />
            </a>
          </div>

          <div class="results_list">
            <SearchItem v-for="(item, index) in products" :key="`${index}_search_pr`" :product="item" />
          </div>

          <br>
          <div v-if="products.length > 3" class="autocomplete_top mob_v">
            <div class="autocomplete_title">
              <p>{{ $t('header.search_results') }}</p>
            </div>
            <a v-if="total > 0" :href="fullLink" class="orange_link">
              <span>{{ $t('header.search_show_all') }} ({{ total }})</span>
              <i class="arrow_right" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import debounce from 'lodash/debounce'
import SearchItem from '@/components/search/SearchItem'

export default {
  components: {
    SearchItem
  },
  data () {
    return {
      active: false,
      loading: false,
      query: null,
      total: 0,
      products: null
    }
  },
  computed: {
    fullLink () {
      return this.localePath({ path: `/search/?query=${encodeURI(this.query)}` })
    }
  },
  methods: {
    closeResults () {
      this.active = false
    },
    filterResults (search) {
      if (search.target.value.length) {
        this.loading = true
        this.searchItems(search.target.value, this)
      } else {
        this.products = []
        this.total = 0
        this.loading = false
      }
    },
    searchItems: debounce((search, vm) => {
      if (search === '') {
        vm.products = []
        vm.total = 0
        vm.loading = false
      } else {
        vm.$axios.get('/api/promo/products/', { params: { query: search, catalog_type: 'catalog', per_page: 10 } }).then((res) => {
          if (res.status === 200) {
            vm.products = res.data.data
            vm.total = res.data.meta.total
            // vm.query = search
            vm.loading = false
          }
        })
      }
    }, 1000)
  }
}
</script>
