<template>
  <header>
    <div class="header_wrapper">
      <div class="header_top">
        <div class="container">
          <div class="header_top_wrap">
            <div class="header_top_left">
              <button class="mob_menu_btn" aria-label="Open mob menu" @click="openMobMenu">
                <img src="/icons/mob-menu.svg" :alt="$imgMeta('alt', 'Mobile navigation')" :title="$imgMeta('title', 'Mobile navigation')" width="16" height="16">
              </button>
              <div class="header_logo_desktop">
                <nuxt-link
                  v-if="!$route.name.match(/index/)"
                  :to="$formatUrl(localePath('/'))"
                  class="header_logo_link"
                >
                  <img
                    src="/icons/logo.svg"
                    :alt="$t('header.logotitle')"
                    :title="$t('header.logotitle')"
                    width="116"
                    height="73"
                  >
                </nuxt-link>
                <div v-else class="header_logo_link">
                  <img
                    src="/icons/logo.svg"
                    :alt="$t('header.logotitle')"
                    :title="$t('header.logotitle')"
                    width="116"
                    height="73"
                  >
                </div>
              </div>
              <div class="header_phone_wrap">
                <div class="header_phone custom_dropdown">
                  <div class="phone_wrap dropdown_btn">
                    <i class="phone_icon" />
                    <a :href="formattedPhoneLink" class="phone" v-text="settings.header_phone" />
                    <i v-if="itemsInfoMenu" class="drop_arrow" />
                  </div>
                  <div v-if="itemsInfoMenu" class="dropdown_content">
                    <div class="dc_wrap">
                      <a v-for="(item, index) in itemsInfoMenu" :key="`${index}_phones_m`" :href="item.href" class="phone" v-text="item.title" />
                    </div>
                  </div>
                </div>
                <span>{{ $t('header.by_tariff') }}</span>
              </div>
              <div
                id="headerSearchForm"
                ref="headerSearchForm"
                class="header_search"
                :class="mobSearchOpened"
              >
                <SearchWrap />
                <div class="search_bg" @click="closeMobSearch" />
              </div>
            </div>
            <div class="header_top_right">
              <button class="mob_search" aria-label="Open mob search" @click="openMobSearch" />
              <button class="favorite_link item_with_counter" aria-label="Open favorites" @click="openFavorites">
                <img src="/icons/heart-black.svg" alt="" width="20" height="20">
                <span class="count">{{ total_favorites }}</span>
              </button>
              <button class="open_cart_btn item_with_counter" aria-label="Open cart" @click="openCart">
                <i class="cart_icon" />
                <span class="total" v-text="$moneyFormat(totals.sub_total || 0)" />
                <i class="drop_arrow" />
                <span class="count">{{ total }}</span>
              </button>
              <button class="login_btn" aria-label="Login" @click="openAccount">
                <i class="login_icon" />
                <span v-text="authenticated ? $t('profile.lk') : $t('header.login')" />
                <i v-if="!authenticated" class="drop_arrow" />
              </button>
              <Login />
            </div>
          </div>
        </div>
      </div>
      <div class="header_bottom">
        <div class="container">
          <div class="header_bottom_wrap">
            <Megamenu :menu="itemsHeaderMenu1" />
            <div class="header_bottom_main">
              <div class="header_nav_wrap">
                <HeaderNav :menu="itemsHeaderMenu2" />
                <HeaderLinks :menu="itemsHeaderMenu3" />
              </div>
            </div>
            <div class="lang_wrap">
              <i class="lang_icon" />
              <div class="lang_list">
                <template v-for="(locale, index) in availableLocales">
                  <span v-if="index !== 0" :key="`${locale.code}_span_1`"> / </span>
                  <nuxt-link
                    :key="`${locale.code}_link_1`"
                    class="lang_item"
                    :class="(locale.code == $i18n.locale)?' selected':''"
                    :to="$formatUrl(switchLocalePath(locale.code))"
                    v-text="locale.name"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mob_menu_wrap"
      :class="mobMenuOpened"
    >
      <div class="mob_menu">
        <div class="mob_menu_top">
          <button class="close_btn close_mob_menu" aria-label="Close mob menu" @click="closeMobMenu">
            <img src="/icons/close-2.svg" width="27" height="26" alt="close button" loading="lazy">
          </button>
          <nuxt-link
            v-if="!$route.name.match(/index/)"
            :to="$formatUrl(localePath('/'))"
            class="header_logo_link"
          >
            <img
              src="/icons/logo.svg"
              :alt="$imgMeta('alt', 'Logo desktop')"
              :title="$imgMeta('title', 'Logo desktop')"
              width="121"
              height="76"
            >
          </nuxt-link>
          <div v-else class="header_logo_link">
            <img
              src="/icons/logo.svg"
              :alt="$imgMeta('alt', 'Logo desktop')"
              :title="$imgMeta('title', 'Logo desktop')"
              width="121"
              height="76"
            >
          </div>
          <button class="login_btn" @click="openAccount">
            <i class="login_icon" />
            <span v-text="authenticated ? $t('profile.lk') : $t('header.login')" />
            <i v-if="!authenticated" class="drop_arrow" />
          </button>
        </div>
        <div class="mob_menu_main">
          <div class="mob_menu_nav">
            <nuxt-link
              v-for="(item, index) in itemsHeaderMenu2"
              :key="`${index}_header_link`"
              :to="$formatUrl(item.href)"
              class="nav_link"
              data-sale="sales_link"
              v-html="$options.filters.transl(item, 'title', $i18n.locale)"
            />
          </div>
          <div class="mob_menu_item">
            <div class="lang_wrap">
              <i class="lang_icon" />
              <div class="lang_list">
                <template v-for="(locale, index) in availableLocales">
                  <span v-if="index !== 0" :key="`${locale.code}_span_2`"> / </span>
                  <nuxt-link
                    :key="`${locale.code}_link_2`"
                    class="lang_item"
                    :class="(locale.code == $i18n.locale)?' selected':''"
                    :to="$formatUrl(switchLocalePath(locale.code))"
                    v-text="locale.name"
                  />
                </template>
              </div>
            </div>
            <HeaderLinks :menu="itemsHeaderMenu3" />
          </div>
          <div class="mob_menu_item">
            <div class="header_phone_wrap">
              <div class="header_phone custom_dropdown">
                <div class="phone_wrap dropdown_btn">
                  <i class="phone_icon" />
                  <a :href="formattedPhoneLink" class="phone" v-text="settings.header_phone" />
                  <i v-if="itemsInfoMenu" class="drop_arrow" />
                </div>
                <div v-if="itemsInfoMenu" class="dropdown_content">
                  <div class="dc_wrap">
                    <a v-for="(item, index) in itemsInfoMenu" :key="`${index}_phones_m`" :href="item.href" class="phone" v-text="item.title" />
                  </div>
                </div>
              </div>
              <span>{{ $t('header.by_tariff') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mob_menu_bg" @click="closeMobMenu" />
  </header>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Megamenu from '../components/menu/Megamenu'
import HeaderNav from '../components/menu/HeaderNav'
import HeaderLinks from '../components/menu/HeaderLinks'
import Login from '../components/modals/Login'
import SearchWrap from './search/SearchWrap'

export default {
  components: {
    Megamenu,
    HeaderNav,
    HeaderLinks,
    SearchWrap,
    Login
  },
  data () {
    return {
      mob_menu_opened: false,
      mob_search_opened: false
    }
  },
  computed: {
    ...mapGetters('settings', ['settings']),
    ...mapGetters('cart', ['total', 'totals', 'total_favorites']),
    availableLocales () {
      return this.$i18n.locales
    },
    formattedPhoneLink () {
      return this.settings.header_phone ? 'tel:' + this.settings.header_phone.replace(/[' ']/g, '') : ''
    },
    mobMenuOpened () {
      return this.mob_menu_opened ? 'opened_in_mob' : ''
    },
    mobSearchOpened () {
      return this.mob_search_opened ? 'opened_in_mob' : ''
    },
    itemsHeaderMenu1 () {
      return (this.settings && this.settings.headerMenu1 && this.settings.headerMenu1.status === 'enabled') ? this.settings.headerMenu1.items : []
    },
    itemsHeaderMenu2 () {
      return (this.settings && this.settings.headerMenu2 && this.settings.headerMenu2.status === 'enabled') ? this.settings.headerMenu2.items : []
    },
    itemsHeaderMenu3 () {
      return (this.settings && this.settings.headerMenu3 && this.settings.headerMenu3.status === 'enabled') ? this.settings.headerMenu3.items : []
    },
    itemsInfoMenu () {
      return (this.settings && this.settings.infoMenu && this.settings.infoMenu.status === 'enabled') ? this.settings.infoMenu.items : null
    }
  },
  mounted () {
    this.fetchSession()
    this.$nuxt.$on('mm_hide', this.closeMobMenu)
  },
  beforeDestroy () {
    this.$nuxt.$off('mm_hide')
  },
  methods: {
    ...mapActions('cart', ['fetchSession']),
    openMobSearch () {
      this.mob_search_opened = !this.mob_search_opened
      this.$refs.headerSearchForm.querySelector('input').focus()
    },
    openMobMenu () {
      this.mob_menu_opened = !this.mob_menu_opened
    },
    closeMobSearch () {
      this.mob_search_opened = false
    },
    closeMobMenu () {
      this.mob_menu_opened = false
      this.$nuxt.$emit('msm_hide')
      this.$nuxt.$emit('cart_hide')
    },
    openCallback () {
      this.$nuxt.$emit('callback_open')
    },
    openCart () {
      this.$nuxt.$emit('cart_open')
    },
    openAccount () {
      if (this.authenticated) {
        this.$router.push(this.localePath({ name: 'account' }))
      } else {
        this.$nuxt.$emit('login_open')
      }
    },
    openFavorites () {
      if (this.authenticated) {
        this.$router.push(this.localePath({ name: 'account-favorites' }) + '/')
      } else {
        this.$nuxt.$emit('login_open')
      }
    }
  }
}
</script>
