<template>
  <div
    id="menuContainer"
    class="header_menu"
    :class="mobSubMenuOpened"
  >
    <nuxt-link :to="catalog_link" class="header_menu_link has_child">
      <img
        class="header_menu_icon"
        src="/icons/menu.svg"
        width="24"
        height="24"
        alt="Catalog"
        title="Catalog Decibel"
      >
      <span class="text">
        {{ $t('header.catalog') }}
      </span>
      <i class="drop_arrow" @click.prevent="openMobSubMenu()" />
    </nuxt-link>
    <client-only>
      <div class="catalog_menu_wrap">
        <div class="submenu_head">
          <span class="go_back close_submenu" @click="closeMobSubMenu" />
          <span class="title">{{ $t('header.catalog') }}</span>
        </div>
        <ul class="catalog_menu custom_scrollbar">
          <template v-for="(item, index) in sortedItems">
            <li
              v-if="item.parent_id === null"
              :key="`${index}_itemsHMenu2`"
              :ref="`${index}_itemsHMenu2`"
            >
              <template v-if="item.children">
                <nuxt-link v-if="!item.blank" :to="$formatUrl($options.filters.transl(item, 'href', $i18n.locale))" class="has_sub_child">
                  <img
                    v-if="item && item.avatar"
                    :src="getMiniAvatar(item)"
                    :alt="`Category ${item.title}`"
                    class="categories_icon"
                    width="28"
                    height="28"
                    loading="lazy"
                  >
                  {{ $options.filters.transl(item, 'title', $i18n.locale) }}
                  <i v-if="item.children.length" class="arrow" @click.prevent="openSubChild(`${index}_itemsHMenu2`)" />
                </nuxt-link>
                <a v-else :href="$options.filters.transl(item, 'href', $i18n.locale)" target="_blank" class="has_sub_child">
                  <img
                    v-if="item && item.avatar"
                    :src="getMiniAvatar(item)"
                    :alt="`Category ${item.title}`"
                    class="categories_icon"
                    width="28"
                    height="28"
                    loading="lazy"
                  >
                  {{ $options.filters.transl(item, 'title', $i18n.locale) }}
                  <i v-if="item.children.length" class="arrow" @click.prevent="openSubChild(`${index}_itemsHMenu2`)" />
                </a>
                <div class="csw">
                  <div class="submenu_head">
                    <span class="go_back close_subsubmenu" @click="closeSubChild(`${index}_itemsHMenu2`)" />
                    <span class="title">{{ $options.filters.transl(item, 'title', $i18n.locale) }}</span>
                  </div>
                  <div v-if="item.children.length" class="csw_lc custom_scrollbar">
                    <ul>
                      <li v-for="(child, subindex) in getSortedItems(item.children)" :key="`${index}_${subindex}_child_itemsHMenu2`" :ref="`${index}_${subindex}_child_itemsHMenu2`">
                        <nuxt-link v-if="!item.blank" :to="$formatUrl($options.filters.transl(child, 'href', $i18n.locale))" class="has_sub_child">
                          <nuxt-img
                            v-if="child && child.avatar"
                            :src="getAvatar(child)"
                            :alt="$imgMeta('alt', child.title)"
                            :title="$imgMeta('title', child.title)"
                            class="categories_icon"
                            width="28"
                            height="28"
                            format="webp"
                            background="transparent"
                            layout="responsive"
                            fit="outside"
                            loading="lazy"
                          />
                          {{ $options.filters.transl(child, 'title', $i18n.locale) }}
                          <i v-if="child.children.length" class="arrow" @click.prevent="toggleSubSubChild(`${index}_${subindex}_child_itemsHMenu2`)" />
                        </nuxt-link>
                        <a v-else :href="$options.filters.transl(child, 'href', $i18n.locale)" target="_blank" class="has_sub_child">
                          <nuxt-img
                            v-if="child && child.avatar"
                            :src="getAvatar(child)"
                            :alt="$imgMeta('alt', child.title)"
                            :title="$imgMeta('title', child.title)"
                            class="categories_icon"
                            width="28"
                            height="28"
                            format="webp"
                            background="transparent"
                            layout="responsive"
                            fit="outside"
                            loading="lazy"
                          />
                          {{ $options.filters.transl(child, 'title', $i18n.locale) }}
                          <i v-if="child.children.length" class="arrow" @click.prevent="toggleSubSubChild(`${index}_${subindex}_child_itemsHMenu2`)" />
                        </a>
                        <div v-if="child.children.length" class="csw_rc custom_scrollbar">
                          <div class="csw_product_list">
                            <template v-for="(subchild, subsubindex) in getSortedItems(child.children)">
                              <nuxt-link v-if="!item.blank" :key="`${subsubindex}_child_itemsHMenu3`" :to="$formatUrl($options.filters.transl(subchild, 'href', $i18n.locale))" class="csw_product_item">
                                <span class="img_wrap">
                                  <nuxt-img
                                    v-if="subchild && subchild.avatar"
                                    :src="getAvatar(subchild)"
                                    :alt="$imgMeta('alt', subchild.title)"
                                    :title="$imgMeta('title', subchild.title)"
                                    class="categories_icon"
                                    width="90"
                                    height="90"
                                    format="webp"
                                    background="transparent"
                                    layout="responsive"
                                    fit="outside"
                                    loading="lazy"
                                  />
                                </span>
                                <span v-text="$options.filters.transl(subchild, 'title', $i18n.locale)" />
                              </nuxt-link>
                              <a v-else :key="`${subsubindex}_child_itemsHMenu3`" :href="$options.filters.transl(subchild, 'href', $i18n.locale)" target="_blank" class="csw_product_item">
                                <span class="img_wrap">
                                  <nuxt-img
                                    v-if="subchild && subchild.avatar"
                                    :src="getAvatar(subchild)"
                                    :alt="$imgMeta('alt', subchild.title)"
                                    :title="$imgMeta('title', subchild.title)"
                                    class="categories_icon"
                                    width="90"
                                    height="90"
                                    format="webp"
                                    background="transparent"
                                    layout="responsive"
                                    fit="outside"
                                    loading="lazy"
                                  />
                                </span>
                                <span v-text="$options.filters.transl(subchild, 'title', $i18n.locale)" />
                              </a>
                            </template>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template v-else>
                <nuxt-link v-if="!item.blank" :to="$formatUrl(item.href)">
                  <img src="/icons/subwoofer.svg" class="categories_icon" :alt="item.title" loading="lazy">
                  {{ $options.filters.transl(item, 'title', $i18n.locale) }}
                </nuxt-link>
                <a v-else :href="item.href" target="_blank">
                  <img src="/icons/subwoofer.svg" class="categories_icon" :alt="item.title" loading="lazy">
                  {{ $options.filters.transl(item, 'title', $i18n.locale) }}
                </a>
              </template>
            </li>
          </template>
        </ul>
      </div>
    </client-only>
    <div class="catalog_menu_bg" @click="closeMobSubMenu" />
  </div>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      mob_sub_menu_opened: false,
      catalog_link: this.$formatUrl(this.localePath({ name: 'catalog' }))
    }
  },
  computed: {
    mobSubMenuOpened () {
      return this.mob_sub_menu_opened ? 'mob_open' : ''
    },
    sortedItems () {
      return this.menu.length ? [...this.menu].sort((a, b) => a.position - b.position) : []
    }
  },
  mounted () {
    this.$nuxt.$on('msm_hide', this.closeMobSubMenu)
  },
  methods: {
    openSubChild (ref) {
      this.$refs[ref][0].classList.add('mob_open')
    },
    closeSubChild (ref) {
      this.$refs[ref][0].classList.remove('mob_open')
    },
    toggleSubSubChild (ref) {
      this.$refs[ref][0].classList.toggle('active')
    },
    openMobSubMenu () {
      this.mob_sub_menu_opened = !this.mob_sub_menu_opened
    },
    closeMobSubMenu () {
      this.mob_sub_menu_opened = false
    },
    getAvatar (item) {
      return this.$options.filters.mediaUrl(item, 'avatar', 'path')
    },
    getMiniAvatar (item) {
      return this.$options.filters.mediaUrl(item, 'avatar', 'url')
    },
    getSortedItems (items) {
      return items.length ? [...items].sort((a, b) => a.position - b.position) : []
    }
  }
}
</script>
