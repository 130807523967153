<template>
  <div
    v-if="login_opened"
  >
    <div
      id="singModalBg"
      class="opened"
      @click="hide()"
    />
    <div
      class="modal sign_modal"
      :class="login_class"
    >
      <div class="sign_modal_wrap ">
        <div class="sign_tabs_wrap">
          <div class="sign_content">
            <div class="sign_content_item custom_content_item active">
              <div class="from_wrap">
                <h3 class="title" v-text="$t('login.title')" />
                <template v-if="!showWelcome">
                  <template v-if="!sentCode">
                    <p class="descr" v-text="$t('login.subtitle1')" />
                    <br>
                    <div class="input_group" :class="errors && errors.phone ? 'error' : ''">
                      <masked-input
                        v-model="phone"
                        mask="\+38(111)111-11-11"
                        type="tel"
                        class="custom_input"
                        placeholder=" "
                      />
                      <span class="floating_label" v-text="$t('login.phone')" />
                      <i class="phone_icon" />
                    </div>
                  </template>
                  <template v-else>
                    <p class="descr" v-text="$t('login.subtitle2')" />
                    <br>
                    <div class="input_group" :class="errors && errors.code ? 'error' : ''">
                      <input
                        v-model="code"
                        type="text"
                        class="custom_input"
                        placeholder=" "
                      >
                      <span class="floating_label" v-text="$t('login.code')" />
                      <i class="password_icon" />
                    </div>

                    <div v-if="!showReSent">
                      <p class="descr">
                        {{ $t('login.login_send_again_by') }} <span class="seconds_left" v-text="sms_time" /> сек...
                      </p>
                      <br>
                      <button class="orange_btn sign_btn orange_gradient_btn" @click="login" v-text="$t('login.login')" />
                    </div>
                    <button v-else class="link_blue resend_btn" @click="changePhone">
                      <b v-text="$t('login.login_send_again')" />
                    </button>
                  </template>
                </template>
                <template v-if="showWelcome">
                  <p class="descr" v-text="$t('login.subtitle3')" />
                  <form action="javascript:void(0)" @submit="updateProfile">
                    <div
                      class="input_group"
                      :class="errors && errors.first_name ? 'error' : ''"
                    >
                      <input v-model="first_name" type="text" class="custom_input" placeholder=" ">
                      <span class="floating_label">{{ $t('login.login_name') }}</span>
                      <i class="user_icon" />
                    </div>
                    <div
                      class="input_group"
                      :class="errors && errors.last_name ? 'error' : ''"
                    >
                      <input v-model="last_name" type="text" class="custom_input" placeholder=" ">
                      <span class="floating_label">{{ $t('login.login_lastname') }}</span>
                      <i class="user_icon" />
                    </div>
                    <div
                      class="input_group"
                      :class="errors && errors.email ? 'error' : ''"
                    >
                      <input v-model="email" type="email" class="custom_input" placeholder=" ">
                      <span class="floating_label">{{ $t('login.login_email') }}</span>
                      <i class="email_icon" />
                    </div>
                    <button class="orange_btn sign_btn orange_gradient_btn" v-text="$t('login.login_register')" />
                  </form>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      sentCode: false,
      loginProcess: false,
      phone: '',
      code: '',
      first_name: '',
      last_name: '',
      email: '',
      sms_time: 60,
      login_class: 'closed',
      login_opened: false,
      smsTimer: false,
      welcome: false,
      redirect: true
    }
  },
  computed: {
    ...mapGetters('cart', ['sessionId']),
    ...mapGetters('validation', ['errors']),
    showReSent () {
      return this.sms_time === 0
    },
    showWelcome () {
      return this.welcome
    }
  },
  watch: {
    phone (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue.replace(/[^0-9 ]/g, '').length === 12) {
          this.sentPhone()
        }
      }
    }
  },
  mounted () {
    this.$nuxt.$on('login_nor_open', this.showNor)
    this.$nuxt.$on('login_open', this.show)
    this.$nuxt.$on('login_hide', this.hide)
  },
  beforeDestroy () {
    this.$nuxt.$off('login_nor_open')
    this.$nuxt.$off('login_open')
    this.$nuxt.$off('login_hide')
  },
  destroyed () {
    this.clearErrors()
  },
  methods: {
    ...mapActions('validation', ['setErrors', 'clearErrors']),
    showNor () {
      this.login_opened = true
      this.redirect = false
      const self = this
      setTimeout(function () {
        self.login_class = 'opened'
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }, 300)
    },
    show () {
      this.login_opened = true
      const self = this
      setTimeout(function () {
        self.login_class = 'opened'
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }, 300)
    },
    hide () {
      this.login_class = 'closed'
      this.redirect = true
      const self = this
      setTimeout(function () {
        self.login_opened = false
      }, 500)
    },
    startTimer () {
      this.smsTimer = setInterval(function () {
        this.sms_time = this.sms_time - 1
        if (this.sms_time <= 1) {
          clearInterval(this.smsTimer)
          this.smsTimer = false
          this.sms_time = 0
        }
      }.bind(this), 1000)
    },
    changePhone () {
      this.phone = ''
      this.sentCode = false
    },
    async sentPhone () {
      try {
        if (this.phone.replace(/[^0-9 ]/g, '').length === 12) {
          await this.$axios.post('/api/phone/send/', {
            phone: this.phone.replace(/[^0-9 ]/g, '')
          })
            .then(() => {
              this.sms_time = 60
              this.startTimer()
              this.sentCode = true
            })
            .catch((error) => {
              this.sentCode = false
              this.setErrors(error.response.data.errors)
            })
        }
      } catch (e) {
        this.errors = e.response.data.errors
        this.loginProcess = false
      }
    },
    async login () {
      this.loginProcess = true
      try {
        await this.$auth.loginWith('local', {
          data: {
            code: this.code,
            'guest-token': `${this.sessionId}`
          }
        })
          .then((response) => {
            const { data, token } = response.data
            this.$auth.setUserToken(token)
            this.$auth.setUser(JSON.stringify(data))
            this.loginProcess = false
            if (!data.first_name || data.first_name === '' || data.first_name === data.phone) {
              this.welcome = true
            } else {
              this.hide()
              if (this.redirect) {
                this.$router.push(this.localePath({ name: 'account' }) + '/')
              }
            }
          })
      } catch (e) {
        this.errors = e.response.data.errors
        this.loginProcess = false
      }
    },
    async updateProfile () {
      try {
        await this.$axios.put('/api/profile/', {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email
        })
          .then(() => {
            this.hide()
            if (this.redirect) {
              this.$router.push(this.localePath({ name: 'account' }) + '/')
            }
          })
      } catch (e) {
        this.setErrors(e.response.data.errors)
      }
    }
  }
}
</script>
