<template>
  <div class="rating_box">
    <span class="reviews_count r_t" v-text="rating.toFixed(1)" />
    <span
      v-for="i in [1,2,3,4,5]"
      :key="`${i}_rating`"
      class="star"
      :class="rating >= i ? 'star_full' : ''"
    >
      <span class="fill" :style="`clip-path: polygon(0% 0%, ${calcWith(i)}% 0%, ${calcWith(i)}% 100%, 0% 100%);`" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    rating: {
      type: Number,
      default: 0
    }
  },
  methods: {
    calcWith (i) {
      return (i - this.rating) > 1 ? 0 : parseInt(this.rating) >= i ? 100 : (100 - (i - this.rating).toFixed(2) * 100)
    }
  }
}
</script>
