<template>
  <footer>
    <div class="footer">
      <div class="footer_top">
        <div class="container">
          <div class="footer_top_wrap">
            <div class="ft_left">
              <div class="logo_wrap">
                <div class="logo">
                  <img
                    src="/icons/logo-big.svg"
                    :alt="$t('header.logotitle')"
                    :title="$t('header.logotitle')"
                    width="163"
                    height="103"
                    loading="lazy"
                  >
                </div>
                <div class="info">
                  <div class="shops" v-html="$i18n.t('footer.addresses_html')" />
                  <div class="copyright">
                    {{ $t('footer.copy') }} © 2023
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="item" :class="tabOneOpened">
                  <div class="footer_nav_title footer_tab" @click="openTabOne">
                    {{ $t('footer.menu_info') }}
                    <i class="drop_arrow" />
                  </div>
                  <ul class="footer_nav_list">
                    <li
                      v-for="(item, index) in itemsMenu1"
                      :key="`${index}_itemsMenu1`"
                    >
                      <nuxt-link :to="$formatUrl($options.filters.transl(item, 'href', $i18n.locale))" class="footer_nav_link">
                        {{ $options.filters.transl(item, 'title', $i18n.locale) }}
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="ft_right">
              <div class="col">
                <div class="item" :class="tabTwoOpened" @click="openTabTwo">
                  <div class="footer_nav_title footer_tab">
                    {{ $t('footer.menu_other') }}
                    <i class="drop_arrow" />
                  </div>
                  <ul class="footer_nav_list">
                    <li
                      v-for="(item, index) in itemsMenu2"
                      :key="`${index}_itemsMenu1`"
                    >
                      <nuxt-link :to="$formatUrl($options.filters.transl(item, 'href', $i18n.locale))" class="footer_nav_link">
                        {{ $options.filters.transl(item, 'title', $i18n.locale) }}
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col">
                <div class="item" :class="tabThreeOpened" @click="openTabThree">
                  <div class="footer_nav_title footer_tab">
                    {{ $t('footer.menu_contacts') }}
                    <i class="drop_arrow" />
                  </div>
                  <ul class="footer_nav_list">
                    <li
                      v-for="(item, index) in itemsMenu3"
                      :key="`${index}_itemsMenu1`"
                    >
                      <nuxt-link :to="$formatUrl($options.filters.transl(item, 'href', $i18n.locale))" class="footer_nav_link">
                        {{ $options.filters.transl(item, 'title', $i18n.locale) }}
                      </nuxt-link>
                    </li>
                  </ul>
                </div>
                <div class="item no_dropdown">
                  <div class="footer_nav_title">
                    {{ $t('footer.support') }}
                  </div>
                  <div class="phone_wrap">
                    <a :href="formattedPhoneLink" class="footer_phone" v-text="settings.footer_phone" />
                    <div class="descr">
                      {{ $t('footer.free_call') }}
                    </div>
                  </div>
                  <a :href="formattedEmailLink" class="footer_email">{{ $t('footer.email') }} {{ settings.email }}</a>
                  <button class="footer_login login_btn" @click="openAccount">
                    <i class="log_icon" />
                    <span>{{ $t('footer.login') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <div class="container">
          <div class="footer_bottom_wrap">
            <div class="fb_payment_wrap fb_item">
              <div class="descr">
                {{ $t('footer.payments') }}
              </div>
              <div class="f_payment_list">
                <img src="/img/payment-1.png" alt="mastercard" width="24" height="19" loading="lazy">
                <img src="/img/payment-2.png" alt="visa" width="30" height="11" loading="lazy">
              </div>
            </div>
            <div class="fb_socials fb_item">
              <div class="descr">
                {{ $t('footer.follow') }}
              </div>
              <div class="fb_socials_list">
                <a
                  v-for="(social, index) in itemsMenu4"
                  :key="`${index}_itemsMenu4`"
                  :href="social.href"
                  target="_blank"
                  rel="nofollow"
                  class="social_link"
                >
                  <img
                    :src="$options.filters.mediaUrl(social, 'avatar', 'url')"
                    :alt="social.title"
                    :title="social.title"
                    width="23"
                    height="23"
                    loading="lazy"
                  >
                </a>
              </div>
            </div>
            <button class="grey_link" @click="openCallback">
              <span>
                {{ $t('footer.feedback') }}
              </span>
              <i class="drop_arrow" />
            </button>
            <a href="https://abvv.group/" class="grey_link developer" rel="nofollow" target="_blank" title="Developed by ABVV.Group">
              <span>Developed by ABVV.Group</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Chat />
  </footer>
</template>
<script>
import { mapGetters } from 'vuex'
import Chat from '@/components/modals/Chat'

export default {
  components: {
    Chat
  },
  data () {
    return {
      tab_one_opened: false,
      tab_two_opened: false,
      tab_three_opened: false
    }
  },
  computed: {
    ...mapGetters('settings', ['settings']),
    formattedPhoneLink () {
      return this.settings.footer_phone ? 'tel:' + this.settings.footer_phone.replace(/[' ']/g, '') : ''
    },
    formattedEmailLink () {
      return 'mailto:' + this.settings.email
    },
    itemsMenu1 () {
      return (this.settings.footerMenu1 && this.settings.footerMenu1.status === 'enabled')
        ? [...this.settings.footerMenu1.items].sort((a, b) => a.position - b.position)
        : []
    },
    itemsMenu2 () {
      return (this.settings.footerMenu2 && this.settings.footerMenu2.status === 'enabled')
        ? [...this.settings.footerMenu2.items].sort((a, b) => a.position - b.position)
        : []
    },
    itemsMenu3 () {
      return (this.settings.footerMenu3 && this.settings.footerMenu3.status === 'enabled')
        ? [...this.settings.footerMenu3.items].sort((a, b) => a.position - b.position)
        : []
    },
    itemsMenu4 () {
      return (this.settings.footerMenu4 && this.settings.footerMenu4.status === 'enabled')
        ? [...this.settings.footerMenu4.items].sort((a, b) => a.position - b.position)
        : []
    },
    tabOneOpened () {
      return this.tab_one_opened ? 'opened' : ''
    },
    tabTwoOpened () {
      return this.tab_two_opened ? 'opened' : ''
    },
    tabThreeOpened () {
      return this.tab_three_opened ? 'opened' : ''
    }
  },
  methods: {
    openTabOne () {
      this.tab_one_opened = !this.tab_one_opened
    },
    openTabTwo () {
      this.tab_two_opened = !this.tab_two_opened
    },
    openTabThree () {
      this.tab_three_opened = !this.tab_three_opened
    },
    openCallback () {
      this.$nuxt.$emit('callback_open')
    },
    scrollToTop () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    openAccount () {
      if (this.authenticated) {
        this.$router.push(this.localePath({ name: 'account' }))
      } else {
        this.$nuxt.$emit('login_open')
      }
    }
  }
}
</script>
